import {createGlobalStyle} from 'styled-components';
import {device} from "./device"

const GlobalStyle = createGlobalStyle`
    body{
        font-family:'Roboto', sans-serif;;
        overflow:hidden;
    }
    h1,h2,h3,h4,h5,h6{
        font-family:'Roboto', sans-serif;;
        font-weight: 600;
        margin-bottom:20px;
        color:#3a3a3a;
    }
    h1{
        font-size:48px;
        line-height:54px;

        @media ${device.laptop} {
            font-size:38px;
            line-height:44px;
        }
        @media ${device.mobileL} {
            font-size:32px;
            line-height:38px;
        }
    }
    h2{
        font-size:36px;
        line-height:42px;

        @media ${device.tablet} {
            font-size:30px;
            line-height:36px;
        }
    }
    h3{
        font-size:24px;
        line-height:32px;

        @media ${device.tablet} {
            font-size:20px;
            line-height:26px;
        }
    }
    h4{
        font-size:22px;
        line-height:28px;
    }
    h5{
        font-size:20px;
        line-height:26px;
    }
    h6{
        font-size:18px;
        line-height:24px;
    }
    a{
        font-size:16px;
        text-decoration:none;
        :hover{
            text-decoration:none;
        }
    }
    p{
        color: #7a7f83;
        margin-bottom: 20px;
        font-size: 15px;
        line-height:1.7;
    }
    img{
        margin-bottom:0px;
    }
    .navbar-expand-lg .navbar-collapse{
        justify-content: right;
    }
`;

export default GlobalStyle;
