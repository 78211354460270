import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {SectionHeading} from '../Common/common.style';
import {KeyFeaturesSection,KeyFeatInner,FeatureRow,
    KeyFeatLeft,KeyFeatLeftCol,KeyFeatLeftIconWrapper,
    KeyFeatLeftIcon,KeyFeatLeftContent,
    KeyFeatRight} from './keyfeatures.style'
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

const KeyFeatures = () => {
    const JSONData  = useStaticQuery(graphql`{
  corporateJson {
    KeyFeatures {
      DelayConstant
      SectionHeading
      KeyFeatLeftCol {
        KeyFeatLeftIconimg
        KeyFeatLeftTitle
        KeyFeatLeftPara
      }
      KeyFeatRightimg {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`);
            return (
                <KeyFeaturesSection id="featuresContainer">
                    <Container>
                        <Row>
                            <Col md="12">
                                <Fade bottom delay={1*JSONData.corporateJson.KeyFeatures.DelayConstant}>
                                    <SectionHeading>{JSONData.corporateJson.KeyFeatures.SectionHeading}</SectionHeading>
                                </Fade>
                            </Col>
                        </Row>
                        <KeyFeatInner>
                            <FeatureRow>
                                <Col lg="6">
                                    <KeyFeatLeft>
                                    {
                                        JSONData.corporateJson.KeyFeatures.KeyFeatLeftCol.map((item,idx) => {
                                        return <Fade left delay={(idx+1)*JSONData.corporateJson.KeyFeatures.DelayConstant}><KeyFeatLeftCol>
                                            <KeyFeatLeftIconWrapper>
                                                <KeyFeatLeftIcon>
                                                    <img src={item.KeyFeatLeftIconimg} alt=""/>
                                                </KeyFeatLeftIcon>
                                            </KeyFeatLeftIconWrapper>
                                            <KeyFeatLeftContent>
                                                <h4>{item.KeyFeatLeftTitle}</h4>
                                                <p>{item.KeyFeatLeftPara}</p>
                                            </KeyFeatLeftContent>
                                        </KeyFeatLeftCol>
                                        </Fade>
                                        })
                                    }
                                    </KeyFeatLeft>
                                </Col>
                                <Col lg="6">
                                    <Fade right delay={1*JSONData.corporateJson.KeyFeatures.DelayConstant}>
                                        <KeyFeatRight>
                                            <GatsbyImage
                                                image={JSONData.corporateJson.KeyFeatures.KeyFeatRightimg.childImageSharp.gatsbyImageData}
                                                className="KeyFeatRightimg"
                                                imgStyle={{
                                                    marginBottom:"0px"
                                                }}
                                                alt="" />
                                        </KeyFeatRight>
                                    </Fade>
                                </Col>
                            </FeatureRow>
                        </KeyFeatInner>
                    </Container>
                </KeyFeaturesSection>
            );
    }

export default KeyFeatures;
