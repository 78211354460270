import React,{Component} from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {TeamSlider,TeamSection,TeamSliderCol,TeamImgFigure,
    TeamDesc,TeamSocial,FbIcon,TwitterIcon,LinkedinIcon,
    InstaIcon,TeamSliderDiv,NextPrev,ImgButton,PrevImg,NextImg} from './team.style';
import {SectionHeading} from '../Common/common.style';
import Slider from "react-slick";
import Fade from "react-reveal/Fade";
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class TeamComponent extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render(){
        var settings = {
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                  breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                }
            ]
        };

        return (
            <TeamSection id="teamContainer">
                <Container>
                    <Row>
                        <Col md="12">
                            <Fade bottom delay={this.props.corporateJson.Team.DelayConstant}>
                                <SectionHeading>{this.props.corporateJson.Team.SectionHeading}</SectionHeading>
                            </Fade>
                        </Col>
                    </Row>

                </Container>
                <Container>
                    <Fade bottom delay={this.props.corporateJson.Team.DelayConstant}>
                        <TeamSlider>
                            <Slider ref={c => (this.slider = c)}  {...settings}>

                            {
                                this.props.corporateJson.Team.TeamSlider.map((item,idx) => {
                                return (
                                    <TeamSliderCol>
                                        <TeamSliderDiv>
                                            <TeamImgFigure>
                                                <GatsbyImage 
                                                image={item.TeamSliderImg.childImageSharp.gatsbyImageData} 
                                                className="authorImg"
                                                alt="" />
                                            </TeamImgFigure>
                                            <TeamDesc>
                                                <h3>{item.TeamName}</h3>
                                                <p>{item.TeamDesignation}</p>
                                                <TeamSocial href="#" aria-label="Facebook Link">
                                                    <FbIcon/>
                                                </TeamSocial>
                                                <TeamSocial href="#" aria-label="Twitter Link">
                                                    <TwitterIcon/>
                                                </TeamSocial>
                                                <TeamSocial href="#" aria-label="LinkedIn Link">
                                                    <LinkedinIcon/>
                                                </TeamSocial>
                                                <TeamSocial href="#" aria-label="Instagram Link">
                                                    <InstaIcon/>
                                                </TeamSocial>
                                            </TeamDesc>
                                            </TeamSliderDiv>
                                    </TeamSliderCol>
                                );
                                })
                            }
                            </Slider>
                        </TeamSlider>
                    </Fade>
                    <Fade bottom delay={this.props.corporateJson.Team.DelayConstant}>
                        <NextPrev>
                                <ImgButton onClick={this.previous} aria-label="Prev Button">
                                    <PrevImg src={this.props.corporateJson.Team.PrevImg} alt=""/>
                                </ImgButton>
                                <ImgButton onClick={this.next} aria-label="Next Button">
                                    <NextImg src={this.props.corporateJson.Team.NextImg} alt=""/>
                                </ImgButton>
                        </NextPrev>
                    </Fade>
                </Container>
            </TeamSection>
        );
    }
}

const Team = () => (
    <StaticQuery
        query={graphql`{
  corporateJson {
    Team {
      DelayConstant
      SectionHeading
      TeamSlider {
        TeamSliderImg {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        TeamName
        TeamDesignation
        TeamSocial {
          TeamSocialImg
        }
      }
      PrevImg
      NextImg
    }
  }
}
`}
        render={(data) => (
            <TeamComponent corporateJson={data.corporateJson}/>
        )}
    />
)
export default Team;
