import React from 'react';
import {BannerWrapper,
    BannerContents,
    BannerContentLeft,
    BannerContentRight,
    BannerBtn
} from './banner.style'
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

const Banner = () => {
    const JSONData  = useStaticQuery(graphql`{
  corporateJson {
    Banner {
      BannerBtn
      BannerH3
      BannerImage {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      DelayConstant
      BannerPara
    }
  }
}
`);

    return (
        <BannerWrapper id="homeContainer">
            <Container>
                <BannerContents>
                    <BannerContentLeft>
                        <Fade bottom delay={1*JSONData.corporateJson.Banner.DelayConstant}>
                        <div>
                            <h1>{JSONData.corporateJson.Banner.BannerH3}</h1>
                            <p>{JSONData.corporateJson.Banner.BannerPara}</p>
                            <BannerBtn href="#">{JSONData.corporateJson.Banner.BannerBtn}</BannerBtn>
                        </div>
                        </Fade>
                    </BannerContentLeft>

                    <BannerContentRight>
                        <Fade bottom delay={1*JSONData.corporateJson.Banner.DelayConstant}>
                            <GatsbyImage
                                image={JSONData.corporateJson.Banner.BannerImage.childImageSharp.gatsbyImageData}
                                className="BannerImageHolder"
                                imgStyle={{
                                    maxHeight:"430px",
                                    width:"auto",
                                    left:"0px",
                                    right:"0px",
                                    margin:"0px auto"
                                }}
                                alt="" />
                        </Fade>
                    </BannerContentRight>

                </BannerContents>
            </Container>
        </BannerWrapper>
    );
}

export default Banner;
