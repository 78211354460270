import React from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FooterWrapper,FooterCol,
    FooterUlWrapper,FooterLi,FooterSocial,BottomLink,
    FbIcon,TwitterIcon,LinkedinIcon,InstaIcon,BottomFooterWrapper,YTIcon}
    from './footer.style';
import Fade from "react-reveal/Fade"

import ScrollSpy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useStaticQuery, graphql } from 'gatsby';


const Footer = () =>{
    const JSONData  = useStaticQuery(graphql`
    query{
        corporateJson {
            Footer {
                DelayConstant
                FooterCol1{
                    FooterTitle
                    FooterPara{
                        Para
                    }
                }
                FooterCol2{
                    FooterTitle
                    FooterPara{
                        Para
                    }
                }
                FooterCol3{
                    FooterTitle
                    Items
                    FooterUl{
                        Menu
                        Href
                    }
                }
                FooterCol4{
                    FooterTitle
                    FooterSocial{
                        FooterSocialImg
                    }
                }
                BottomFooterPara
                BottomFooterName
                BottomFooterLink
            }
        }
    }
`);
        return (
        <div>
        <FooterWrapper>
            <Container>
                <Fade Bottom delay={JSONData.corporateJson.Footer.DelayConstant}>
                    <Row>
                        <Col md="6">

                            <FooterCol>
                                <h3>{JSONData.corporateJson.Footer.FooterCol2.FooterTitle}</h3>
                                {
                                    JSONData.corporateJson.Footer.FooterCol2.FooterPara.map((item,idx) => {
                                    return <p>
                                        {item.Para}
                                        </p>
                                    })
                                }
                            </FooterCol>
                        </Col>
                        <Col md="3">
                            <FooterCol>
                                <h3>{JSONData.corporateJson.Footer.FooterCol3.FooterTitle}</h3>
                                <FooterUlWrapper>
                                    <ScrollSpy offset={-59} items={JSONData.corporateJson.Footer.Items} currentClassName="is-current">
                                    {
                                        JSONData.corporateJson.Footer.FooterCol3.FooterUl.map((item,idx) => {
                                        return <FooterLi>
                                            <AnchorLink offset={55} href={item.Href}>
                                                {item.Menu}
                                            </AnchorLink>
                                        </FooterLi>
                                        })
                                    }
                                    </ScrollSpy>
                                </FooterUlWrapper>
                            </FooterCol>
                        </Col>
                        <Col md="3">
                            <FooterCol>
                                {/* <FooterTitle>{JSONData.corporateJson.Footer.FooterCol4.FooterTitle}</FooterTitle> */}
                                <h3>{JSONData.corporateJson.Footer.FooterCol1.FooterTitle}</h3>
                                {
                                    JSONData.corporateJson.Footer.FooterCol1.FooterPara.map((item,idx) => {
                                    return <p>
                                        {item.Para}
                                        </p>
                                    })
                                }
                                <FooterSocial href="#" aria-label="Facebook Link">
                                    <FbIcon/>
                                </FooterSocial>
                                <FooterSocial href="#" aria-label="Twitter Link">
                                    <TwitterIcon/>
                                </FooterSocial>
                                <FooterSocial href="#" aria-label="LinkedIn Link">
                                    <LinkedinIcon/>
                                </FooterSocial>
                                <FooterSocial href="#" aria-label="Instagram Link">
                                    <InstaIcon/>
                                </FooterSocial>
                                <FooterSocial href="#" aria-label="Youtube Link">
                                    <YTIcon/>
                                </FooterSocial>
                            </FooterCol>
                        </Col>
                    </Row>
                </Fade>
            </Container>
        </FooterWrapper>
        <BottomFooterWrapper>
            <Container>
                <Fade bottom delay={JSONData.corporateJson.Footer.DelayConstant}>
                    <Row>
                        <Col md="12">
                            <p>
                                {JSONData.corporateJson.Footer.BottomFooterPara}
                                <BottomLink rel="noreferrer" href={JSONData.corporateJson.Footer.BottomFooterLink} target="_blank">
                                {JSONData.corporateJson.Footer.BottomFooterName}
                                </BottomLink>
                            </p>
                        </Col>
                    </Row>
                </Fade>
            </Container>
        </BottomFooterWrapper>
        </div>
    );
}

export default Footer;
