import React from "react";
import Banner from "../containers/Corporate/Banner";
import HeaderMenuBS from "../containers/Corporate/HeaderMenuBS";
import KeyServices from "../containers/Corporate/KeyServices";
import Portfolio from "../containers/Corporate/Portfolio";
import AboutSection from "../containers/Corporate/About";
import KeyFeatures from "../containers/Corporate/KeyFeatures";
import Contact from "../containers/Corporate/Contact";
import ClientsSection from "../containers/Corporate/Clients";
import NewsLetter from "../containers/Corporate/SubscribeLetter";
import Footer from "../containers/Corporate/Footer";
import Team from "../containers/Corporate/Team";
import Testimonials from "../containers/Corporate/Testimonials";
import GlobalStyle from "../containers/Corporate/Common/global-styles";
import "../components/layout.css";
import Seo from "../components/seo"
import Fonts from "../containers/Corporate/Common/fonts"
import BuyNow from "../components/BuyNow";

const Corporate = () => (
   <div>
       <Fonts />
        <GlobalStyle />
        <BuyNow />
        <HeaderMenuBS/>

        <main>
            <Seo 
                title="Corporate ReactJS Landing Template | GatsbyJS Templates | Zircon"
                description="Buy Corporate Landing page template from Reactrepo. Template is built with react, gatsbyjs, bootstrap and styled components"
            />
            <Banner/>
            <KeyServices/>
            <AboutSection/>
            <Portfolio/>
            <KeyFeatures />
            <Team/>
            <Testimonials />
            <Contact />
            <ClientsSection />
            <NewsLetter />
        </main>

        <Footer/>
   </div>

)

export default Corporate;
