import React from 'react';
import {TestimonialsSection,TestimonialWrapper,TestimonialsLeftCol,TestimonialHeading,
    TestimonialsRightCol,CardOuterWrapper,Card,CardProfileLayout,
    ProfilePicLayout,ProfileNameLayout} from './testimonials.style';
import Slider from "react-slick";
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

var settings = {
    dots: true,
    arrows:false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.4,
    slidesToScroll: 1,
    appendDots: dots => (
        <div
          style={{
            textAlign:"left",
            position: "relative",
            bottom:0
          }}
        >
          <ul style={{ margin: "0px",lineHeight:"0px"}}> {dots} </ul>
        </div>
      ),
      customPaging: i => (
        <div style={{paddingTop:10}}>
        <span
          style={{
            display:"inline-block",
            width: "20px",
            height:"3px",
            background:"#fff",
            borderRadius: "3px",
            opacity:0.6
          }}
        >
        </span>
        </div>
      ),
    responsive: [
        {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
        }
    ]
};

const Team = () => {
    const JSONData  = useStaticQuery(graphql`{
  corporateJson {
    Testimonials {
      DelayConstant
      TestimonialHeading
      TestimonialSubHeading
      TestimonialPara
      CardProfileLayout {
        ProfilePic {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        ProfileName
        Testimonial
      }
    }
  }
}
`);

    return (
        <TestimonialsSection id="testimonialsContainer">
            <TestimonialWrapper>
            <TestimonialsLeftCol>
                <Fade bottom delay={JSONData.corporateJson.Testimonials.DelayConstant}>
                    <TestimonialHeading>{JSONData.corporateJson.Testimonials.TestimonialHeading}</TestimonialHeading>
                    <p>{JSONData.corporateJson.Testimonials.TestimonialPara}</p>
                </Fade>
            </TestimonialsLeftCol>

            <TestimonialsRightCol>
                <Fade bottom delay={JSONData.corporateJson.Testimonials.DelayConstant}>
                    <Slider {...settings}>
                    {
                            JSONData.corporateJson.Testimonials.CardProfileLayout.map((item,idx) => {
                            return (
                                <CardOuterWrapper>
                                    <Card>
                                        <CardProfileLayout>
                                            <ProfilePicLayout>
                                                <GatsbyImage
                                                    image={item.ProfilePic.childImageSharp.gatsbyImageData}
                                                    className="ProfilePic"
                                                    alt="" />
                                            </ProfilePicLayout>
                                            <ProfileNameLayout>
                                                <h3>
                                                    {item.ProfileName}
                                                </h3>
                                            </ProfileNameLayout>
                                        </CardProfileLayout>
                                        <p>
                                        {item.Testimonial}
                                        </p>
                                    </Card>
                                </CardOuterWrapper>
                            );
                            })
                        }
                    </Slider>
                </Fade>
            </TestimonialsRightCol>
            </TestimonialWrapper>
        </TestimonialsSection>
    );
}

export default Team;
