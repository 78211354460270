import styled from 'styled-components';
import {Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {device} from '../Common/device'
import BackgroundImg from "../../../assets/corporate-images/bg-pattern.png"

export const KeyFeaturesSection = styled.section`
    padding:0px 0px 80px;
    position:relative;
    ::before{
        content:"";
        background-image:url(${BackgroundImg});
        display: block;
        background-repeat: no-repeat;
        position: absolute;
        right: -110px;
        height: 200px;
        width: 200px;
        background-size: 100%;
        top: 30px;
    }

    @media ${device.tablet} {
        padding-right: 10px;
        padding-left: 10px;
    }
`;

export const KeyFeatInner = styled.div`
    margin-top:15px;
`;

export const FeatureRow = styled(Row)`
    align-items:center;
`;

export const KeyFeatLeft = styled.div`

`;

export const KeyFeatLeftCol = styled.div`
    display:flex;
    align-items:center;
    margin:30px 0px;
    background: #fff;
    margin-right:10px;
    border-radius: 6px;
    padding: 15px 30px;
    transition:all .3s;
    border:1px solid #eaeaea;

    :hover{
        box-shadow: 0px 0px 12px #e4e4e4;
        transition:all .3s;
        transform:scale(1.05);
    }

    :last-child{
        margin:30px 0px 0px;
    }

    @media ${device.tablet} {
        padding: 15px 15px;
        margin-right:0px;
    }
`;

export const KeyFeatLeftIconWrapper = styled.div`
    margin-right:20px;
    flex-shrink:0;
`;

export const KeyFeatLeftIcon = styled.div`
    img{
        margin-bottom:0px;
        width: 40px;
    }
`;


export const KeyFeatLeftContent = styled.div`
    h4{
        margin-bottom:0px;
        font-size:20px;
        @media ${device.tablet} {
            font-size:18px;
        }
    }
    p{
        margin-bottom:0px;
    }
`;



export const KeyFeatRight = styled.div`
    margin-left:30px;
    @media ${device.laptop} {
        margin-left:0px;
        margin-top:30px;
    }

    .KeyFeatRightimg{
        width:100%;
    }
`;
