import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {SectionHeading} from '../Common/common.style';
import {ClientsSection,ClientsInner,ClientsCol} from './clients.style'
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

const Clients = () => {
    const JSONData  = useStaticQuery(graphql`{
  corporateJson {
    Clients {
      DelayConstant
      SectionHeading
      SectionSubHeading
      ClientsInner1 {
        ClientsImage {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
      ClientsInner2 {
        ClientsImage {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
}
`);

        return (
            <ClientsSection>
                <Container>
                    <Row>
                        <Col md="12">
                            <Fade bottom delay={1*JSONData.corporateJson.Clients.DelayConstant}>
                                <SectionHeading>Our Clients</SectionHeading>
                            </Fade>
                        </Col>
                    </Row>
                    <ClientsInner>
                        <Row>
                            {
                                JSONData.corporateJson.Clients.ClientsInner1.map((item,idx)=>{
                                    return (
                                        <Col md="2" xs="6">
                                            <Fade bottom delay={(idx+1)*JSONData.corporateJson.Clients.DelayConstant}>
                                                <ClientsCol>
                                                    <GatsbyImage
                                                        image={item.ClientsImage.childImageSharp.gatsbyImageData}
                                                        className="ClientsImage"
                                                        alt="" />
                                                </ClientsCol>
                                            </Fade>
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                        <Row>
                            {
                                JSONData.corporateJson.Clients.ClientsInner2.map((item,idx)=>{
                                    return (
                                        <Col md="2" xs="6">
                                            <Fade bottom delay={(idx+1)*JSONData.corporateJson.Clients.DelayConstant}>
                                                <ClientsCol>
                                                    <GatsbyImage
                                                        image={item.ClientsImage.childImageSharp.gatsbyImageData}
                                                        className="ClientsImage"
                                                        alt="" />
                                                </ClientsCol>
                                            </Fade>
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                        
                    </ClientsInner>
                </Container>
            </ClientsSection>
        );
    }

export default Clients;