import React,{Component} from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import  {PorfolioSlider,PortfolioSection,PorfolioSliderCol,
    PortfolioInnerContainer,PrevImg,NextImg,NextPrev,ImgButton,
    SliderHover} from './portfolio.style';
import {SectionHeading} from '../Common/common.style';
import Slider from "react-slick";
import Fade from "react-reveal/Fade";
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Portfolio extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            className: "center-portfolio",
            centerMode: true,
            infinite: true,
            arrows:false,
            centerPadding:"0px",
            slidesToShow: 3,
            speed: 1000,
            responsive: [
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                }
            ]
        };

        return (
            <PortfolioSection id="portfolioContainer">
                <Container>
                    <Row>
                        <Col md="12">
                            <Fade bottom delay={this.props.corporateJson.Portfolio.DelayConstant}>
                                <SectionHeading>{this.props.corporateJson.Portfolio.SectionHeading}</SectionHeading>
                            </Fade>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Fade bottom delay={this.props.corporateJson.Portfolio.DelayConstant}>
                        <PorfolioSlider>
                            <Slider ref={c => (this.slider = c)} {...settings}>
                            {
                                this.props.corporateJson.Portfolio.PorfolioSlider.map((item,idx) => {
                                return (
                                    <PorfolioSliderCol>
                                        <PortfolioInnerContainer>
                                            <GatsbyImage
                                                image={item.PorfolioSliderImg.childImageSharp.gatsbyImageData}
                                                className="PorfolioSliderImg"
                                                alt="" />
                                            <SliderHover>
                                                <h3>
                                                {item.PorfolioSliderTitle}
                                                </h3>
                                                <p>
                                                {item.PorfolioSliderHoverText}
                                                </p>
                                            </SliderHover>
                                        </PortfolioInnerContainer>
                                    </PorfolioSliderCol>
                                );
                                })
                            }
                        </Slider>
                        </PorfolioSlider>
                    </Fade>
                    <Fade bottom delay={this.props.corporateJson.Portfolio.DelayConstant}>
                        <NextPrev>
                            <ImgButton onClick={this.previous} aria-label="Prev Button">
                                <PrevImg src={this.props.corporateJson.Portfolio.PrevImg} alt=""/>
                            </ImgButton>
                            <ImgButton onClick={this.next} aria-label="Next Button">
                                <NextImg src={this.props.corporateJson.Portfolio.NextImg} alt=""/>
                            </ImgButton>
                        </NextPrev>
                    </Fade>
                </Container>
            </PortfolioSection>
        );
    }
}

const PortfolioPageSection = () => (
    <StaticQuery
        query={graphql`{
  corporateJson {
    Portfolio {
      DelayConstant
      SectionHeading
      SectionSubHeading
      PorfolioSlider {
        PorfolioSliderHoverText
        PorfolioSliderTitle
        PorfolioSliderImg {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
      PrevImg
      NextImg
    }
  }
}
`}
        render={(data) => (
            <Portfolio corporateJson={data.corporateJson}/>
        )}
    />
  )
  export default PortfolioPageSection;
